.hero{
    height: auto;
    background-color: $wbgrey;
    background-image: url('../img/ellipse-bg.png');
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    .greyscale{
        filter: grayscale(100%);
        animation: wbanimate 7s infinite linear;
        transition: 1s;
        &:hover{
        filter: grayscale(0%);

        }
    }
    @keyframes wbanimate {
        0%{
            transform:translate(0,0) ;
        }
        50%{
            transform:translate(10px,25px) ;
        }
        100%{
            transform:translate(0px,0px) ;
        }
    }
  
}
.skew-box{
    clip-path: polygon(0 8%, 100% 0, 100% 88%, 0 64%);
    background-color: #007e5b;
    box-shadow: 30px 62px 11px -7px rgba(0,0,0,0.36) inset;
    .skew-right{
        animation: wbanimateone 7s infinite linear;
    }
    @keyframes wbanimateone {
        0%{
            transform:translate(0,-70px) ;
        }
        50%{
            transform:translate(50px,0px) ;
        }
        100%{
            transform:translate(0px,-70px) ;
        }
    }

}

@media(max-width:553px){
    .greyscale{
        width: 300px;
    }
    .skew-line{
        img{
            width: 300px;
        }
    }
    
    .skew-box{
        clip-path: none;
        background-color: #007e5b;
        box-shadow: none;
        
      .skew-right{
        display: none;
      }
    
    }
}

