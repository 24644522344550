.projects{
    .card{
        border: none;
        position: relative;
        // background-color: $wbgrey;
        // border: 2px solid #fff;
        color: #fff;
        box-shadow: 10px 10px 5px 0px rgba(255,255,255,0.75);
        .card-desc{
            position: absolute;
            width: 100%;
            background-color: rgba($color: #000000, $alpha: .5);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: .5s;
        }
        &:hover{
           .card-desc{
            opacity: 1;
           }
        }
    }
}