html{
    overflow-x: hidden !important;
}
li{
    list-style: none
}
a{
    text-decoration: none
}

