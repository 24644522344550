html {
  overflow-x: hidden !important;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.wb-bg {
  background-color: #2a2a2a;
}

.active {
  color: #00c384 !important;
}

nav {
  height: 123px;
}

.navbar-collapse {
  padding: 10px;
  z-index: 5;
  background-color: #2a2a2a;
}
.navbar-collapse * {
  color: #fff;
}

.swal2-styled.swal2-confirm {
  background-color: #2a2a2a !important;
  color: #00c384 !important;
  border: 1px solid #00c384 !important;
}

.swal2-popup {
  background-color: #2a2a2a !important;
  color: #fff !important;
}

.swal2-icon.swal2-info {
  border-color: #00c384 !important;
  color: #00c384 !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgba(126, 224, 102, 0.5) !important;
}

.hero {
  height: auto;
  background-color: #2a2a2a;
  background-image: url("../img/ellipse-bg.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
}
.hero .greyscale {
  filter: grayscale(100%);
  animation: wbanimate 7s infinite linear;
  transition: 1s;
}
.hero .greyscale:hover {
  filter: grayscale(0%);
}
@keyframes wbanimate {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(10px, 25px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.skew-box {
  -webkit-clip-path: polygon(0 8%, 100% 0, 100% 88%, 0 64%);
          clip-path: polygon(0 8%, 100% 0, 100% 88%, 0 64%);
  background-color: #007e5b;
  box-shadow: 30px 62px 11px -7px rgba(0, 0, 0, 0.36) inset;
}
.skew-box .skew-right {
  animation: wbanimateone 7s infinite linear;
}
@keyframes wbanimateone {
  0% {
    transform: translate(0, -70px);
  }
  50% {
    transform: translate(50px, 0px);
  }
  100% {
    transform: translate(0px, -70px);
  }
}

@media (max-width: 553px) {
  .greyscale {
    width: 300px;
  }
  .skew-line img {
    width: 300px;
  }
  .skew-box {
    -webkit-clip-path: none;
            clip-path: none;
    background-color: #007e5b;
    box-shadow: none;
  }
  .skew-box .skew-right {
    display: none;
  }
}
.projects .card {
  border: none;
  position: relative;
  color: #fff;
  box-shadow: 10px 10px 5px 0px rgba(255, 255, 255, 0.75);
}
.projects .card .card-desc {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
}
.projects .card:hover .card-desc {
  opacity: 1;
}

.footer-skew {
  -webkit-clip-path: polygon(0 21%, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(0 21%, 100% 0, 100% 100%, 0 100%);
  width: 100%;
  height: auto;
  background-color: #007e5b;
  box-shadow: 16px 38px 35px 1px rgba(0, 0, 0, 0.84) inset;
}
.footer-skew p, .footer-skew h1, .footer-skew h3, .footer-skew li {
  color: #fff;
}
.footer-skew .line-footer {
  width: 5px;
  height: 30vh;
  transform: translateY(100px);
  background-color: #fff;
}
.footer-skew .bottom-line {
  height: 5px;
  width: 100%;
  background-color: #fff;
}
.footer-skew .footer-right {
  margin-top: 100px;
}

@media (max-width: 768px) {
  .line-footer {
    display: none;
  }
  .footer-nav * {
    color: #fff;
  }
  .footer-skew {
    -webkit-clip-path: none;
            clip-path: none;
    box-shadow: none;
    background-color: #2a2a2a;
  }
}/*# sourceMappingURL=style.css.map */