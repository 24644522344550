.footer-skew{
  clip-path: polygon(0 21%, 100% 0, 100% 100%, 0 100%);
  width: 100%;
  height: auto;
  background-color: #007e5b;
  box-shadow: 16px 38px 35px 1px rgba(0,0,0,0.84) inset;
  p,h1,h3,li{
    color: #fff;
  }
  .line-footer{
    width: 5px;
    height: 30vh;
    transform: translateY(100px);
    background-color: #fff;
  }
  .bottom-line{
    height: 5px;
    width: 100%;
    background-color: #fff;
  }
  .footer-right{
    margin-top: 100px;
  }
}
@media (max-width:768px) {
  .line-footer{
    display: none;
  }
  .footer-nav *{
    color: #fff;
  }
  .footer-skew{
    clip-path: none;
    box-shadow: none;
    background-color: $wbgrey;
  }
}
