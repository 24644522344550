.wb-bg{
    background-color: $wbgrey;
}
.active{
    color: $wbbtn !important;
}
nav{
    
    height: 123px;
}
.navbar-collapse{
    padding: 10px;
    z-index: 5;
    background-color: $wbgrey;
    *{
        color: #fff;
    }
}
.swal2-styled.swal2-confirm{
    background-color:$wbgrey !important;
    color: $wbbtn !important;
    border: 1px solid $wbbtn !important;
}
.swal2-popup{
    background-color: $wbgrey !important;
    color: #fff !important;
}
.swal2-icon.swal2-info{
    border-color: $wbbtn !important;
    color: $wbbtn !important;
}
.swal2-styled.swal2-confirm:focus{
    box-shadow: 0 0 0 3px rgba(126, 224, 102, 0.5) !important;
}